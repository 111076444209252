import { appAxios as axios } from '@/services/api/';

const apiUrl = `/v1`;

export default {
  get(id) {
    return axios.get(`${apiUrl}/messages/${id}`).then((response) => {
      return response.data;
    });
  },
  getList(filter) {
    return axios
      .get(`${apiUrl}/messages`, { params: filter })
      .then((response) => {
        return response.data;
      });
  },
  delete(id) {
    return axios
      .delete(`${apiUrl}/messages/${id}`)
      .then((response) => {
        return response.data;
      });
  },
};
