export default {
  tableColumnProps: [
    {
      label: 'page.message.time',
      prop: 'create_time',
      width: '144px',
      minWidth: null,
      sortable: 'custom',
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.message.user',
      type: null,
      columnKey: null,
      prop: 'u_name',
      width: '104px',
      minWidth: '140px',
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.message.level',
      type: null,
      columnKey: null,
      prop: 'level',
      width: '65px',
      // minWidth: '140px',
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.message.type',
      type: null,
      columnKey: null,
      prop: 'type',
      width: '112px',
      minWidth: '120px',
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.message.command',
      type: null,
      columnKey: null,
      prop: 'command',
      width: null,
      minWidth: '115px',
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.message.desc',
      type: null,
      columnKey: null,
      prop: 'description',
      width: null,
      minWidth: '239px',
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
  ],
};